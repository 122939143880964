import React, { useState, useEffect } from 'react';
import { IoMdAddCircle } from "react-icons/io";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { IoMdClose } from "react-icons/io";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { addNewAdvertisement,getAllAdvertisement,deleteExistingAdvertisement,advertisementUpdate} from '../services/productService';
import { useBranch } from './BranchProvider';
import { showDeleteConfirmation, showSuccessAlert, showErrorAlert, showEditConfirmation } from './Alert';
const Advertisement = () => {
  const [showAdvertisementForm, setShowAdvertisementForm] = useState(false);
  const { selectedBranchId } = useBranch();
  const [advertisements, setAdvertisements] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedAdvertisementForCard, setSelectedAdvertisementForCard] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);
 
  const [updatedAdvertisement, setUpdatedAdvertisement] = useState({
    title: '',
    description: '',
    picture: null,
   
    
});
  const [newAdvertisement, setNewAdvertisement] = useState({
    branchId: '',
    title: '',
    description: '',
    picture: null,
   
});
const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const itemsPerPage = 5; // Número de anuncios por página
useEffect(() => {
  if (selectedBranchId) {
      const fetchAdvertisement = async () => {
          try {
              const response = await getAllAdvertisement(selectedBranchId);
              setAdvertisements(response);
              setPageCount(Math.ceil(response.length / itemsPerPage));
          } catch (error) {
              console.error('Error fetching categories:', error);
          }
      };
      fetchAdvertisement();
  }
}, [selectedBranchId,page]);

const deleteAdvertisement = async id => {
  // Verifica que id esté definido
  //console.log('Deleting advertisement with id:', id);
  showDeleteConfirmation(async () => {
      try {
          await deleteExistingAdvertisement(id);
          setAdvertisements(advertisements.filter(advertisement => advertisement.id !== id));
          showSuccessAlert('El anuncio ha sido eliminado.');
      } catch (error) {
          console.error('Error al eliminar el Anuncio:', error.response ? error.response.data : error.message);
          showErrorAlert('Hubo un problema al eliminar el anuncio.');
      }
  });
};


const createAdvertisement = async () => {
  try {
    const formData = new FormData();
    Object.keys(newAdvertisement).forEach(key => {
      formData.append(key, newAdvertisement[key]);
    });

    const response = await addNewAdvertisement(selectedBranchId, formData);
    console.log("Anuncio creado exitosamente:", response.data);
    showSuccessAlert('¡Anuncio creado exitosamente!');
    // Clear form fields after successful creation
    setNewAdvertisement({
      branchId: selectedBranchId,
      title: '',
      description: '',
      picture: null
    });

    // Optionally, fetch updated list of advertisements
    const updatedAdvertisements = await getAllAdvertisement(selectedBranchId);
    setAdvertisements(updatedAdvertisements);
  } catch (error) {
    console.error('Error creating Anuncio:', error.response ? error.response.data : error.message);
  }
};

const handleNewAdvertisementChange = event => {
  const { name, value, files } = event.target;
  setNewAdvertisement(prevState => ({
    ...prevState,
    [name]: name === 'picture' ? files[0] : value
  }));
};


  const toggleAdvertisementForm = () => setShowAdvertisementForm(!showAdvertisementForm);

  const IMAGE_BASE_URL = 'https://api-copyxpresskaizensoftware.com.ec/';
  const handleRowClick = (event, advertisement) => {
    if (event.target.tagName === 'TD' && event.target.cellIndex === 0) {
        const advertisementWithImageUrl = {
            ...advertisement,
            picture: IMAGE_BASE_URL + advertisement.picture
        };
       setSelectedAdvertisementForCard(advertisementWithImageUrl);
        setIsCardVisible(true);
    }
};



const openEditModal = async (advertisement) => {
  setSelectedAdvertisement(advertisement);

  // Initialize updatedProduct with the selected product's values
  setUpdatedAdvertisement({
      title: advertisement.title,
      description: advertisement.description,
      picture: advertisement.picture
  });

  setEditModalOpen(true);
};

const updateAdvertisement = async () => {
  try {
      const { id } = selectedAdvertisement;

      const formData = new FormData();
      formData.append('picture', updatedAdvertisement.picture);
      formData.append('title', updatedAdvertisement.title);
      formData.append('description', updatedAdvertisement.description);
   
      showEditConfirmation(async () => {
          const response = await advertisementUpdate(id, formData);
          console.log("Anuncio updated successfully:", response.data);
          const updatedResponse = await getAllAdvertisement(selectedBranchId);  // Simplified call
      setAdvertisements(updatedResponse);
          closeEditModal();
          showSuccessAlert('Anuncio actualizado exitosamente.');
      });
  } catch (error) {
      console.error('Error updating product:', error.response ? error.response.data : error.message);
  }
};


const closeEditModal = () => {
  setEditModalOpen(false);
  setSelectedAdvertisement(null);
};
const handleEditChange = event => {
  const { name, value } = event.target;
  setUpdatedAdvertisement(prevState => ({
      ...prevState,
      [name]: value
  }));
};
const handleEditImageChange = event => {
  const file = event.target.files[0];
  setUpdatedAdvertisement(prevState => ({
      ...prevState,
      picture: file
  }));
};


const goToPreviousPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1));
  };
  
  const goToNextPage = () => {
    setPage(prevPage => Math.min(prevPage + 1, pageCount));
  };
  
  const currentAdvertisements = advertisements.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <div>
            <div className="search-bar">
            <div className="search-input">
                    <input
                        type="text"
                        placeholder="Buscar por nombre..."
                        hidden
                    />
                    
                </div>
                <div className="add-options">
                    <div className="add-option" onClick={toggleAdvertisementForm}>
                        <IoMdAddCircle className="add-icon" />
                        <span>Agregar Anuncio</span>
                    </div>
                    
                </div>
            </div>
            {editModalOpen && selectedAdvertisement && (
                <div className="modal-background">
                    <form onSubmit={e => {
                        e.preventDefault();
                        updateAdvertisement();
                    }}>
                        <div className="floating-card">
                            <h3>EDITAR Anuncio</h3>
                            <div className="form-group">
                                <label htmlFor="editName">Titulo</label>
                                <input type="text" id="editTitle" name="title" value={updatedAdvertisement.title} onChange={handleEditChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="editDescription">Descripción</label>
                                <input type="text" id="editDescription" name="description" value={updatedAdvertisement.description} onChange={handleEditChange} required />
                            </div>
                            <div className="form-group">
                            <label htmlFor="editImage">Imagen</label>
                            {updatedAdvertisement.picture && (
                                <img src={typeof updatedAdvertisement.picture === 'string' ? `https://api-copyxpress.com.kaizensoftwaresa.com/${updatedAdvertisement.picture}` : updatedAdvertisement.picture} alt="Vista previa" width="100" />
                            )}
                            <input type="file" id="editImage" name="picture" onChange={handleEditImageChange} accept="image/*" />
                        </div>
                            <div className="button-group">
                                <button type="submit" className='button_primary'>Guardar</button>
                                <button type="button" className='button_danger' onClick={closeEditModal}>Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            )}

            {showAdvertisementForm && (
                <div className="modal-background">
                    <form onSubmit={e => {
                        e.preventDefault();
                        createAdvertisement();
                    }}>
                        <div className="floating-card">
                            <h3>NUEVO Anuncio</h3>
                            <div className="form-group">
                                <label htmlFor="title">Titulo</label>
                                <input type="text" id="title" placeholder="Nombre del anuncio" name="title" value={newAdvertisement.title} onChange={handleNewAdvertisementChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Descripción</label>
                                <input type="text" id="description" placeholder="Descripción" name="description" value={newAdvertisement.description} onChange={handleNewAdvertisementChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="image">Imagen</label>
                                <input type="file" id="picture" name="picture" onChange={handleNewAdvertisementChange} required />
                            </div>
                            <div className="button-group">
                                <button type="submit" className='button_primary'>Guardar</button>
                                <button type="button" className='button_danger' onClick={toggleAdvertisementForm}>Cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
            {isCardVisible && (
                <div className="floating-card">
                    <div className="card-content">
                        <h2>{selectedAdvertisementForCard.title}</h2>
                        <img  src={selectedAdvertisementForCard.picture} alt={selectedAdvertisementForCard.title} width="175" />
                        <p><strong>Descripción:</strong> {selectedAdvertisementForCard.description}</p>
                        <button  className='button_danger' onClick={() => setIsCardVisible(false)}>Cerrar</button>
                    </div>
                </div>
            )}
            <div className="card-container">
                <div className="card">
                    <h2>LISTADO de Anuncios</h2>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Titulo</th>
                                    <th>Imagen</th>
                                    <th>Descripcion</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentAdvertisements.map(advertisement => (
                                    <tr key={advertisement.id} onClick={(e) => handleRowClick(e, advertisement)}>
                                        <td className="product-name">{advertisement.title}</td>
                                        <td>
                                            <img src={`https://api-copyxpress.com.kaizensoftwaresa.com/${advertisement.picture}`} alt={advertisement.title} width="50" />
                                        </td>
                                        <td>{advertisement.description}</td>
                                        <td>
                                            <span onClick={() => openEditModal(advertisement)}>
                                                <i className="fas fa-print"></i> <HiOutlinePencilSquare className="icon-pencil" />
                                            </span>
                                            <span onClick={() => deleteAdvertisement(advertisement.id)}>
                                                <i className="fas fa-trash"></i> <IoMdClose className="icon-pencil-1" />
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-container">
            <button className="pagination-button" onClick={goToPreviousPage} disabled={page === 1}>
              <FiArrowLeft />
            </button>
            <button className="pagination-button" onClick={goToNextPage} disabled={page === pageCount}>
              <FiArrowRight />
            </button>
          </div>
                </div>
            </div>
        </div>
  )
}

export default Advertisement
